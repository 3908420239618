import React from 'react';
import { useHistory } from 'react-router';
import { useExperiment } from 'statsig-react';
import { useResponsive } from '../../../../hooks/use_responsive';
import { RoutePaths } from '../../../../routes';
import { ContentWrapper } from '../../../content_wrapper/content_wrapper';
import { WaterbodySelectValue } from '../../../map/omnia_map_search';
import { MapQueryParams } from '../../../map_default/map_default';
import { OmniaLinkButton } from '../../../omnia_button/omnia_link_button';
import SvgSearch from '../../../svg/search';
import { WaterbodySearchSelect } from '../../../waterbody_search_select/waterbody_search_select';
import styles from './search_hero.less';

export const SearchHero = () => {
  const history = useHistory();
  const { isDesktop } = useResponsive();

  const tabToShow = useExperiment('home_to_map_tab_experiment_2').config.get<'info' | 'hotbaits'>(
    'tab',
    'info'
  );
  const showHotbaitsTab = tabToShow === 'hotbaits';

  const handleSelectChange = (newValue: WaterbodySelectValue) => {
    if (!newValue) {
      return;
    }
    const waterbody = newValue.label.props['data-result'];
    const { url_slug } = waterbody;

    const zoom = isDesktop ? 10 : 9;

    let search = `?${MapQueryParams.waterbody_slug}=${url_slug}&lat=${waterbody.lat}&lng=${waterbody.lng}&zoom=${zoom}&external_search=true`;

    if (showHotbaitsTab) {
      search += `&tab=hotbaits`;
    }

    history.push({
      pathname: RoutePaths.MAP,
      search,
    });
  };

  return (
    <section className={styles.hero}>
      <section className={styles.bg}>
        <ContentWrapper>
          <div className={styles.top}>
            <h1 className={styles.h1}>Hit the Water with the Perfect Plan and Tackle</h1>
            <div className={styles.waterbodySelectWrapper}>
              <WaterbodySearchSelect
                placeholder={'Search by Lake Name'}
                suffixIcon={<SvgSearch width={24} height={24} />}
                allowClear
                showSearch
                labelInValue
                waterbodyLookupValueProp="id"
                onChange={(val: WaterbodySelectValue) => handleSelectChange(val)}
              />
            </div>
            <span className={styles.bottomLine}>
              {' '}
              or
              <OmniaLinkButton to={RoutePaths.MAP} kind="text" size="lg">
                Explore Nearby Lakes
              </OmniaLinkButton>
            </span>
          </div>
        </ContentWrapper>
      </section>
    </section>
  );
};
