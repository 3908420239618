import React from 'react';
import { SearchHero } from './heroes/search_hero/search_hero';

// this component exists just as an old wrapper for experiments
export const HeroLoggedOut = () => {
  return (
    <>
      <SearchHero />
    </>
  );
};
